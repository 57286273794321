@use "palette";
/* import spark design system styles */
@import "node_modules/@sparkdesignsystem/spark/spark.scss";
@import "media-queries";

@font-face {
  font-family: Avenir;
  src: url("./assets/fonts/AvenirLTStd-Medium.otf") format("opentype");
}

b {
  font-weight: bold;
}

p,
body {
  font-family: Avenir;
}

.sprk-u-Overflow--hidden {
  margin-top: -1vh;
}

.sprk-c-Dropdown__link {
  font-size: 5vw !important;
  color: white;
  &:hover {
    background-color: #eab432;
  }
  @include for-tablet-only {
    font-size: 3vw !important;
  }
}

.white-icon-for-mobile {
  fill: white;
}

.sprk-c-Dropdown {
  background-color: #303d4d;
}

// Universal scrollbar styling
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.sprk-c-Stepper__step-content {
  padding: 2% !important;
}

.sprk-c-Button:focus {
  outline: unset;
}

ul ul.indent,
ul.indent {
  padding-left: 25px;
  list-style: none;
}

.borderbottom {
  border-bottom: solid 1px white;
}

b {
  color: palette.$united-way-yellow;
}

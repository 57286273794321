// decimal point in the breakpoint is a quick fix as there is a conflict between the media query breakpoint and spark stack.

@mixin for-mobile-only {
  @media (max-width: 1079.5px) {
    @content;
  }
}

@mixin for-tablet-only {
  @media (min-width: 1079.6px) and (max-width: 1200px) {
    @content;
  }
}

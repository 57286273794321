// App Colors
$united-way-yellow: #eab432;
$united-way-blue: #303d4d;
$primary-color: $united-way-blue;
$secondary-color: $united-way-yellow;
$default-color: #000000;
$united-way-blue-lightened: lighten($united-way-blue, 20%);

// Color Palette
$black: #1c1b1a;
$red: red;
$white: white;
$lightgray: lightgray;

// Button Colors
$button-primary: $united-way-yellow;
$button-primary-hover: darken($united-way-yellow, 10%);
$button-primary-disabled: grayscale($united-way-yellow);
$button-secondary: $united-way-blue-lightened;
$button-secondary-hover: lighten($united-way-blue, 30%);
$button-secondary-disabled: grayscale($united-way-blue);
$button-teriary: $united-way-yellow;
$button-teriary-hover: darken($united-way-yellow, 10%);
$button-teriary-disabled: grayscale($united-way-yellow);
$button-transparent: transparentize($default-color, 1);
$button-transparent-hover: lighten($default-color, 10%);
$button-transparent-disabled: lighten($default-color, 50%);

//Landing Page
$landing-title: #000000;
$landing-text: #ffffff;

//Persona Select Page
$persona-select-background: $united-way-blue;
$persona-info: white;

//Card Component
$decision-card: $primary-color;
$correct-result-card: #15ca04;
$incorrect-result-card: #b81313;

$card-background: #eeeeee;

$radio-box-border: $white;
$radio-container-focus: $united-way-blue-lightened;
$radio-border-focus: lighten($united-way-yellow, 20%);

//Modal
$modal-hint: $united-way-yellow;
$modal-menu: $united-way-yellow;

//Progress Bar
$progress-remaining: #c4c4c4;

$landing-subtitle: #aeaeae;

// reflection page
$reflection-tab: #a9a9a9;

@mixin background {
  background-color: #2e3132;
  background-image: url("../../../assets/images/background/bg.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin backgroundPlain {
  background-color: #2e3132;
}
